import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { changePassword } from "../../service/contentService";
import useModal from "../hooks/useModal";

import "./userdetails.css";
import styled from "styled-components";
import HooxModal from "../HooxModal";

const ShowPasswordIcon = styled.i`
  position: absolute;
  top: 25%;
  right: 12px;
  cursor: pointer;
`;

export default function ChangePassword() {
  const [userCredentials, setUserCredentials] = useState({});
  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // şifre formunu resetle her sayfa acildiginda bos gelsin.
  }, []);

  const handleCredentialChange = (e) => {
    const { value, id } = e.target;
    userCredentials[id] = value;
    setUserCredentials({ ...userCredentials });
  };

  function passwordChangeSubmit(event) {
    const { oldPassword, newPassword, newPasswordRepeat } = userCredentials;
    if (oldPassword !== "" && newPassword !== "" && newPasswordRepeat !== "") {
      changePassword(oldPassword, newPassword, newPasswordRepeat).then(
        (response) => {
          if (response !== undefined && response.data.code === 200) {
            toggleSuccessModal();
          } else {
            setErrorMessage(response.data.message);
          }
        }
      );
      event.preventDefault();
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form onSubmit={passwordChangeSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>ESKİ ŞİFRE</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              id="oldPassword"
              value={userCredentials.oldPassword}
              placeholder="Eski Şifre"
              onChange={handleCredentialChange}
              type={showPassword ? "text" : "password"}
              className="formcontrol-user"
            />
            {showPassword ? (
              <ShowPasswordIcon
                className="bi bi-eye"
                onClick={handleShowPassword}
              ></ShowPasswordIcon>
            ) : (
              <ShowPasswordIcon
                className="bi bi-eye-slash"
                onClick={handleShowPassword}
              ></ShowPasswordIcon>
            )}
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>YENİ ŞİFRE</Form.Label>
          <Form.Control
            id="newPassword"
            value={userCredentials.newPassword}
            placeholder="Yeni Şifre"
            onChange={handleCredentialChange}
            type="password"
            className="formcontrol-user"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>YENİ ŞİFRE TEKRAR</Form.Label>
          <Form.Control
            id="newPasswordRepeat"
            value={userCredentials.newPasswordRepeat}
            placeholder="Yeni Şifre Tekrar"
            onChange={handleCredentialChange}
            type="password"
            className="formcontrol-user"
          />
        </Form.Group>

        <p>{errorMessage}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <Button
            style={{ width: "50%" }}
            className="btn btn-primary checkOutButton w-100"
            type="submit"
          >
            KAYDET
          </Button>
        </div>
      </Form>
      <HooxModal
        isShowing={isShowingSuccess}
        hide={toggleSuccessModal}
        title="BAŞARILI"
      >
        <p style={{ color: "#7b748c" }}>Bilgileriniz Güncellendi</p>
      </HooxModal>
    </>
  );
}
