import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Ticker from "react-ticker";

const HooxSwiperText = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const isMiddle = useMediaQuery({ query: "(min-width: 1600px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  useEffect(() => {
    // İlk renderdan sonra, 100ms sonra satırı göster
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "50px",
        backgroundColor: "transparent",
        color: "#596093",
        fontSize: isMiddle ? "20px" : isMobile ? "10px" : "14px",
        overflow: "hidden",
        marginTop: "5px",
        letterSpacing: "0.3em", // Harf aralıklarındaki boşluğu ayarla
        fontFamily: "GilroyRegular",
        cursor: "default",
      }}
    >
      <Ticker speed={0} move={isVisible}>
        {() => (
          <>
            <span>&nbsp;• BELGESEL</span>
            <span>&nbsp;• SPOR</span>
            <span>&nbsp;• DİZİ</span>
            <span>&nbsp;• STANDUP </span>
          </>
        )}
      </Ticker>
    </div>
  );
};

export default HooxSwiperText;
