import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useVdocipher from "../../hooks/use-vdocipher";
import VideoLayer from "../layer/VideoLayer";
import { fetchEpisode } from "../../pages/content/contentSlice";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import HooxModal, { HOOX_MODAL } from "../HooxModal";
import { useAuth } from "../AuthContext";

export default function VideoPlayer({ setCloseButton }) {
  const { watching, detail, episodes } = useSelector((state) => state.content);
  const { loadVideo, isAPIReady } = useVdocipher();
  const container = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const [player, setPlayer] = useState(null);
  const [hover, setHover] = useState(false);
  const [nextEpisode, setNextEpisdoe] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowingCantWatchDialog, setIsShowingCantWatchDialog] =
    useState(false);
  const { user } = useAuth();
  const { guid } = user;

  async function onNextEpisode() {
    await dispatch(fetchEpisode(nextEpisode));
    setVideoRef(null);
  }

  useEffect(() => {
    const isTVSeries = !!episodes.length;
    //console.log(episodes, watching);
    if (!isTVSeries) {
      return setNextEpisdoe(null);
    }
    const episodeNum = episodes.findIndex(
      (episode) => episode.id === watching.id
    );
    const isHaveNext = episodeNum !== episodes.length - 1;
    if (!isHaveNext) {
      return setNextEpisdoe(null);
    }

    const nextEpisodeId = episodes[episodeNum + 1].guid;

    return setNextEpisdoe(nextEpisodeId);
  }, [watching.id, episodes]);

  useEffect(() => {
    //console.log("watching.usrRight:", watching.usrRight);
    if (watching.usrRight != 1) {
      setIsShowingCantWatchDialog(true);
      //navigate(-1);
    } else {
      if (!isAPIReady) return;
      if (!container.current) return;
      if (videoRef) return;
      container.current.innerHTML = "";
      const player = loadVideo({
        otp: watching.otp,
        playbackInfo: watching.playbackInfo,
        configuration: {
          player: "u31audcQjv1Z8w6D",
          // controls: "off",
        },
        container: container.current,
      });
      setVideoRef(player);
    }
  }, [isAPIReady, loadVideo, watching, videoRef]);

  const handleMouseEnter = () => {
    setHover(true);
    setCloseButton(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHover(false);
      setCloseButton(false);
    }, 4100);
  };

  function returnContentPage() {
    setIsShowingCantWatchDialog(false);
    setCloseButton(true);
    navigate(-1);
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className={isMobile ? "vdo-container-mobile" : "vdo-container"}
          ref={container}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        <VideoLayer
          videoRef={videoRef}
          isAPIReady={isAPIReady}
          isVisible={hover}
          setVisible={setHover}
          style={styles.layer}
          content={detail}
          watchingContent={watching}
          nextEpisodeParams={{
            nextEpisode,
            onNextEpisode,
          }}
        />
      </div>
      <HooxModal
        isShowing={isShowingCantWatchDialog}
        title="Abonelik İşlemi Gerekli"
        type={HOOX_MODAL.INFO}
      >
        <p style={{ color: "#ffffff" }}>
          İçeriği izlemek için abonelik başlatmalı veya paket yükseltmelisiniz.
        </p>
        <Button
          variant="secondary"
          onClick={() => navigate("/abonelik/paketler")}
          style={{ marginRight: "10px", backgroundColor: "rgb(187, 0, 187)" }}
        >
          Abone Ol
        </Button>
        <Button variant="secondary" onClick={() => returnContentPage()}>
          Kapat
        </Button>
      </HooxModal>
    </>
  );
}

const styles = {
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "90vh",
    //backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
};
