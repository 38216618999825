import React, { Component, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import MatomoTracking from "../components/MatomoTracking";
import { useLocation } from "react-router";

const InfoText = styled.div`
  width: ${(props) => (props.isMobile ? "80%" : "40%")};
  height: 100%;
  color: #ffffff;
  font-family: "GilroyLight";
  text-decoration: none;
  font-size: ${(props) => (props.isMobile ? "20" : "24")}px;
  margin-top: ${(props) => (props.isMobile ? "50" : "150")}px;
  min-height: ${(props) => (props.isMobile ? "350" : "800")}px;
  padding-top: ${(props) => (props.isMobile ? "20" : "100")}px;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
`;

function CreationSuccess() {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("gu");

  useEffect(() => {
    if (userId) {
      //console.log("user_id. serbest:", userId);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        userId: userId, //this number must be replaced with an actual User ID
      });
    }
  }, [userId]);

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kayıt Başarılı</title>
        </Helmet>
      </div>
      <div
        style={
          isMobile
            ? {}
            : {
                backgroundImage:
                  "url(https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/40bf0f0b-f01c-4147-2bd0-7e61466ecc00/public)",
                backgroundSize: "cover",
              }
        }
      >
        <div className="container">
          <InfoText isMobile={isMobile}>
            <h1 style={{ marginBottom: "20px" }}>Aramıza Hoşgeldin </h1>
            <p>
              Kayıt olurken girdiğin mail adresinde bir mail attık. Abonelik
              işlemlerini sana gönderdiğimiz mail içindeki{" "}
              <span style={{ textDecoration: "underline" }}>
                "Aboneliğimi Tamamla"
              </span>{" "}
              butonuna tıklayarak tamamlayıp, eğlenceli içeriklerimizin tadını
              çıkarmaya başlayabilirsin.
            </p>
          </InfoText>
        </div>
      </div>
    </>
  );
}

export default CreationSuccess;
