const DiscoverButton = ({ style, onButtonClick, isMobile, showHoox, text }) => {
  return (
    <div
      className="discoverButton"
      style={isMobile ? { ...styles.container, ...style } : style}
    >
      {showHoox ?? (
        <div>
          <h2 style={isMobile ? styles.onlyResponsive : styles.only}>Sadece</h2>
          <div style={styles.hooxContainer}>
            <div style={isMobile ? styles.hooxResponsive : styles.hoox} />
            <h2 style={styles.only}>'ta</h2>
          </div>
        </div>
      )}
      <button
        onClick={onButtonClick}
        className="btn btn-secondary outline-primary"
        style={
          isMobile
            ? {
                ...styles.button,
                width: "240px",
                height: "50px",
                fontSize: "22px",
              }
            : styles.button
        }
      >
        {text}
      </button>
    </div>
  );
};

export default DiscoverButton;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "32px",
  },
  only: {
    color: "white",
    fontSize: "44px",
    fontWeight: "bold",
  },
  hooxContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  hoox: {
    backgroundImage: "url(/assets/img/hoox-logo-welcome.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    width: "180px",
    height: "70px",
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #bc00bc",
    width: "160px",
    height: "36px",
    //backgroundColor: "transparent",
    backgroundColor: "rgb(0, 7, 58)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: 500,
    marginTop: "20px",
    fontFamily: "GilroyLight",
  },

  onlyResponsive: {
    color: "red",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: 0,
  },
  hooxResponsive: {
    backgroundImage: "url(/assets/img/hoox-logo-welcome.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    width: "126px",
    height: "49px",
    marginBottom: "32px",
  },
};
