import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const InfoText = styled.div`
  width: 40%;
  height: 100%;
  color: #ffffff;
  font-family: "GilroyLight";
  text-decoration: none;
  font-size: 24px;
  margin-top: 150px;
  //background-image: url("https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/bc6c0254-8423-45ea-4a97-8e7e91900900/public"); //bc6c0254-8423-45ea-4a97-8e7e91900900
  //background-size: cover;
  min-height: 800px;
  padding-top: 100px;
`;

export default class CreationFailed extends Component {
  render() {
    return (
      <>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Kayıt olma hatası</title>
          </Helmet>
        </div>
        <div
          style={{
            backgroundImage:
              "url(https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/40bf0f0b-f01c-4147-2bd0-7e61466ecc00/public)",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <InfoText>
              <h1 style={{ marginBottom: "20px" }}>Bir Sorun Yaşadık. </h1>
              <p>
                Kayıt olma işlemi sırasında bir sorun yaşadık. Bizimle iletişime
                geçebilirsin.
              </p>
            </InfoText>
          </div>
        </div>
      </>
    );
  }
}
