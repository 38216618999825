import React, { useEffect, useState } from "react";
import { getUserMessages } from "../service/customerService";
import styled from "styled-components";
import moment from "moment";

const MessageText = styled("div")`
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
  margin-top: 15px;

  & .datestr {
    color: gray;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  & .titlestr {
    color: 
    padding-bottom: 10px;
    padding-top: 0px;
  }
`;

const ResponseText = styled("div")`
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  border: 1px solid rgb(158 158 158 / 63%);
  width: 90%;
  padding: 10px;
  margin-top: 2px;
  margin-right: 0px;
  margin-left: auto;

  & .datestr {
    color: gray;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  & .waitstr {
    color: gray;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

export default function Messages(props) {
  const [userMessages, setUserMessages] = useState([]);

  useEffect(() => {
    getUserMessages()
      .then((response) => {
        setUserMessages(response);
        //console.log("response:", response);
      })
      .catch((error) => console.log(error));
  }, [props.refreshList]);

  return (
    <>
      {userMessages !== undefined && userMessages.length !== 0 ? (
        <>
          <Line />
          <div style={{ fontSize: "23px", fontWeight: "500" }}>MESAJLARIM</div>
        </>
      ) : (
        ""
      )}

      {userMessages !== undefined && userMessages.length !== 0
        ? userMessages.map((item, index) => (
            <div style={{ marginBottom: "30px" }}>
              <MessageText key={index}>
                <div className="titlestr">{item.subject}</div>
                {item.message}
                <div className="datestr">
                  {moment(item.date).format("DD/MM/YYYY, h:mm a")}
                </div>
              </MessageText>

              <ResponseText key={"Res" + index}>
                {item.response ? (
                  <div style={{ whiteSpace: "pre-line" }}>{item.response}</div>
                ) : (
                  <div className="waitstr">Yanıt Bekleniyor...</div>
                )}
                {item.responseDate ? (
                  <div className="datestr">
                    {moment(item.responseDate).format("DD/MM/YYYY, h:mm a")}
                  </div>
                ) : null}
              </ResponseText>
            </div>
          ))
        : ""}
    </>
  );
}
