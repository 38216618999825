import { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../AuthContext";
import { getUserDetails } from "../../service/contentService";

import slugifier from "../../util/slugifer";
import { useMediaQuery } from "react-responsive";

const SearchInputStyled = styled("div")`
  width: auto;
  border: ${(props) => (props.value ? "1px solid #BB00BB" : "0")};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${(props) => (props.value ? "5px 10px" : "0")};
  margin-right: 10px;
  height: 36px;
  border-radius: 50px;

  input {
    transition: all 0.4s ease-out;
    width: ${(props) => (props.value ? "200px" : "0")};
    border-radius: 0;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: ${(props) => (props.value ? "0 0 0 10px" : "0")};
    border: 0;
    background-color: transparent;
    opacity: ${(props) => (props.value ? "1" : "0")};
    color: white;
  }

  :focus-within {
    padding: 5px 10px;
    border: 1px solid #bb00bb;
  }

  & input:focus-within {
    width: 200px;
    opacity: 1;
    margin: 0 0 0 10px;
  }

  & label {
    cursor: pointer;
    height: 24px;
  }
`;

// const HooxNavDropdown = styled(NavDropdown)`
//   .dropdown-toggle::after {
//     display: none;
//   }
//   .dropdown-menu {
//     opacity: 0.75;
//   }

//   .dropdown-menu.show {
//     display: grid;
//     grid-template-columns: repeat(6, 1fr);
//   }
// `;

// const HooxNavDropdownItem = styled(NavDropdown.Item)`
//   padding: 10px 40px;
// `;

const AuthUserNav = ({ setExpanded, expanded }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(location.state?.search || "");
  const [avatar, setAvatar] = useState("/assets/img/man.png");

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const { user } = useAuth();
  const { userName = "Hesabım" } = user;

  const { categories, selectedCategory } = useSelector((state) => state.hoox);

  useEffect(() => {
    getUserDetails()
      .then((response) => {
        setAvatar(response.data.imageUrl);
      })
      .catch((error) => console.log(error));
  }, []);

  const enterKeyDownEvent = (e) => {
    if (e.key === "Enter") {
      navigate("/arama", { state: { search: e.target.value } });
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  //console.log("location = ", location);

  /*
  const handleSelectCategory = (categoryName, categoryId) => (e) => {
    navigate(`/home/${slugifier(categoryName)}/${categoryId}`);
  };
*/

  return (
    <>
      <Nav className="me-auto">
        {/* <div style={{paddingLeft: '1.5rem', paddingTop: '1rem'}}> */}
        {/* <HooxNavDropdown className='d-flex' title={<span className="menu-item">Kategoriler</span>}>
                      {categories.map((category) => {
                          const { name, id } = category;
                          return (
                              <HooxNavDropdownItem
                                  key={id}
                                  onClick={handleSelectCategory(name, id)}
                                  style={{ fontFamily: 'GilroyLight' }}
                              >
                                  {name}
                              </HooxNavDropdownItem>
                          )
                      })}
                  </HooxNavDropdown> */}

        {/*
        location.pathname.startsWith("/home") &&
          categories &&
          categories.map((category) => {
            const { name, id } = category;
            return (
              <Link
                key={id}
                className="nav-link d-flex"
                to={`/home/${slugifier(name)}/${id}`}
                onClick={() => setExpanded(expanded ? false : expanded)}
              >
                <span
                  className="menu-item"
                  style={{
                    color: selectedCategory === id ? "#bb00bb" : "white",
                  }}
                >
                  {name}
                </span>
              </Link>
            );
          })
          */}
      </Nav>
      <Nav>
        <div className="nav-link">
          <SearchInputStyled
            onKeyDown={enterKeyDownEvent}
            value={location.state?.search}
            className="mt-2"
            style={{ paddingLeft: "1.5rem" }}
          >
            <label htmlFor="Search">
              <i className="material-icons text-white">search</i>
            </label>
            <input
              id="Search"
              type="text"
              placeholder="Ara..."
              value={search}
              onChange={(e) => handleChange(e)}
            />
          </SearchInputStyled>
        </div>
        <Link
          className="nav-link d-flex align-items-center"
          to="/favorites"
          onClick={() => setExpanded(expanded ? false : expanded)}
        >
          <span className="menu-item">Listem</span>
        </Link>
        <Link
          className={`nav-link text-white d-flex align-items-center ${
            isMobile ? "m-3" : ""
          }`}
          to="/user-details"
          onClick={() => setExpanded(expanded ? false : expanded)}
        >
          <img src={avatar} style={{ width: "45px" }} alt="avatar" />{" "}
          <span
            style={{
              marginLeft: "20px",
              fontSize: "20px",
              fontFamily: "GilroyLight",
            }}
          >
            {" "}
            {userName}{" "}
          </span>
        </Link>
      </Nav>
    </>
  );
};

export default AuthUserNav;
