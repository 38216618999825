import Axios from "../util/apiConfig";

Axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token;
  return config;
});

const LOGIN_EP = "aut/auth/v3/fkgt";

export async function sendTracking(pageUrl) {
  return Axios.get("/aut/track/web", {
    params: {
      url: pageUrl,
    },
  })
    .then((result) => result.data)
    .catch((error) => {
      return error;
    });
}

export async function getNewToken(token) {
  return Axios.post("/aut/auth/v3/gnt", 
    { token },
  )
    .then((response) => 
      {
        if (response.data.code === 200) {
          localStorage.setItem("authToken", response.data.data.token);
          localStorage.setItem("ft", response.data.data.refreshToken);
          localStorage.setItem("tokenDate", new Date().getTime() + 5);
          localStorage.setItem("isUser", "true");
          localStorage.setItem("userName", response.data.data.username);
          //localStorage.setItem("userId", response.data.data.id);
          //localStorage.setItem("customerId", response.data.data.customerId);
          localStorage.setItem("guid", response.data.data.guid);
        }
        
        return response.data
      }
  )
    .catch((error) => {
      return error;
    });
}

export async function logout() {
  return Axios.post("aut/auth/logout", {})
    .then((response) => {
      //console.log("response:", response);
      if (response?.status === 200) {
        localStorage.clear()
      }
      return response;
    })
    .catch((error) => {
      if (
        (error.response && error.response.status) === 401 ||
        ((error.response && error.response.status) === 500 &&
          (error.response && error.response.data.status) === 440)
      ) {
      }
      console.error(error);
    });
}

export async function login(username, password) {
  return Axios.post(LOGIN_EP, {
    //post("aut/auth/login", {
    deviceMacId: "XC-12",
    deviceName: "Web",
    password,
    sessionID: "12345678945",
    tenantID: "1",
    userID: 0,
    username,
  })
    .then((response) => {
      if (response.data.code === 200) {
        localStorage.setItem("authToken", response.data.data.token);
        localStorage.setItem("ft", response.data.data.refreshToken);
        localStorage.setItem("tokenDate", new Date().getTime() + 5);
        localStorage.setItem("isUser", "true");
        localStorage.setItem("userName", response.data.data.username);
        //localStorage.setItem("userId", response.data.data.id);
        //localStorage.setItem("customerId", response.data.data.customerId);
        localStorage.setItem("guid", response.data.data.guid);
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response !== undefined &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data.status === 440))
      ) {
        console.log("refreshToken")
      }
      return error;
    });
}

export async function forceLogin(username, password) {
  return Axios.post(LOGIN_EP + "?accept=true", { // aut/auth/login?accept=true
    deviceMacId: "XC-12",
    deviceName: "Web",
    password,
    sessionID: "12345678945",
    tenantID: "1",
    userID: 0,
    username,
  })
    .then((response) => {
      if (response.data.code === 200) {
        localStorage.setItem("authToken", response.data.data.token);
        localStorage.setItem("ft", response.data.data.refreshToken);
        localStorage.setItem("tokenDate", new Date().getTime() + 5);
        localStorage.setItem("isUser", "true");
        localStorage.setItem("userName", response.data.data.username);
        //localStorage.setItem("userId", response.data.data.id);
        //localStorage.setItem("customerId", response.data.data.customerId);
        localStorage.setItem("guid", response.data.data.guid);
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response !== undefined &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data.status === 440))
      ) {
        console.log("refreshToken")
      }
      return error;
    });
}

export async function resetPassword(email) {
  return Axios.post("aut/cst/resetpassword", {
    email,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        console.log("refreshToken")
      }
      return error;
    });
}

export async function resetPasswordCode(email) {
  return Axios.post("aut/cst/rstpswcode", {
    email,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        console.log("refreshToken")
      }
      return error;
    });
}

export async function resetPasswordV2(code, newPassword, newPasswordAgain) {
  return Axios.post("aut/cst/v2/resetpassword", {
    code,
    newPassword,
    newPasswordAgain,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        console.log("refreshToken")
      }
      return error;
    });
}
