import { Col, Container, Nav, Row, Tab, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEpisode,
  fetchEpisodes,
  selectWatchingContent,
} from "../pages/content/contentSlice";
import styled from "styled-components";
import ContentButtonGroup from "./ContentButtonGroup";
import { useAuth } from "./AuthContext";
import { USER_RIGHT_IN_PACKAGE } from "../util/constants";
import { useNavigate } from "react-router";
import useModal from "./hooks/useModal";
import HooxModal, { HOOX_MODAL } from "./HooxModal";
import { useMediaQuery } from "react-responsive";
import {
  getContentDetail,
  getContentDetailByGuid,
} from "../service/contentService";

const HooxSeasonButton = styled(Nav.Link)`
  font-family: "GilroyMedium";
  color: white;
  background-color: #00073a !important;
  margin-bottom: 8px;
  border-radius: 15px !important;
  font-size: 22px;

  &:hover,
  :active {
    color: #bb00bb !important;
  }
`;

const EpisodeImage = styled.div`
  width: 100%;
  height: 100%;
  background: url("${(props) => props.src}");
  background-position: center;
  background-size: cover;
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

export function EpisodeViewer({ content, onPlayVideo }) {
  const { user } = useAuth();
  const { isLoggedIn, guid } = user;

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  //const guid2 = localStorage.getItem("guid");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [firstSeason] = content.seasons;
  const [seasonId, setSeasonId] = useState(firstSeason?.guid);
  const episodes = useSelector((state) => state.content.episodes);
  const watchContent = useSelector((state) => state.content.watching);
  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();
  const { isShowing: isShowingLogin, toggle: toggleSuccessLogin } = useModal();

  useEffect(() => {
    dispatch(fetchEpisodes(seasonId));
  }, [content.id]);

  const handleSeasonSelect = (seasonId) => (e) => {
    setSeasonId(seasonId);
    dispatch(fetchEpisodes(seasonId));
  };

  const handlePlayClick = async (episodeGuid) => {
    //const contentDetailResponse = await getContentDetail(episodeId);

    const contentDetailResponse = await getContentDetailByGuid(episodeGuid);

    if (isLoggedIn) {
      if (contentDetailResponse.data.usrRight === USER_RIGHT_IN_PACKAGE) {
        dispatch(selectWatchingContent(contentDetailResponse.data));
        onPlayVideo();
        navigation("play");
      } else {
        toggleSuccessModal();
      }
    } else {
      toggleSuccessLogin();
    }
  };

  return (
    <Container>
      <HooxModal
        isShowing={isShowingSuccess}
        title="Abonelik İşlemi Gerekli"
        type={HOOX_MODAL.INFO}
      >
        <p style={{ color: "#ffffff" }}>
          İçeriği izlemek için abonelik başlatmalı veya paket yükseltmelisiniz.
        </p>
        <Button
          variant="secondary"
          onClick={() => navigation("/abonelik/paketler")}
          style={{ marginRight: "10px", backgroundColor: "rgb(187, 0, 187)" }}
        >
          Abone Ol
        </Button>
        <Button variant="secondary" onClick={toggleSuccessModal}>
          Kapat
        </Button>
      </HooxModal>
      <HooxModal
        isShowing={isShowingLogin}
        title="Giriş Yapmalısınız"
        type={HOOX_MODAL.INFO}
      >
        <p style={{ color: "#ffffff", marginBottom: "60px" }}>
          İçeriği izlemek için giriş yapmalısınız.
        </p>
        <Button
          variant="secondary"
          onClick={() => navigation("/user/login")}
          style={{ marginRight: "10px", backgroundColor: "rgb(187, 0, 187)" }}
        >
          Tamam
        </Button>
        <Button variant="secondary" onClick={toggleSuccessLogin}>
          Kapat
        </Button>
      </HooxModal>
      <Tab.Container defaultActiveKey={`season-${seasonId}`}>
        <Row style={{ marginTop: "40px" }}>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {content.seasons.map((season) => {
                const { id, title, guid } = season;
                return (
                  <HooxSeasonButton
                    key={id}
                    eventKey={`season-${guid}`}
                    onClick={handleSeasonSelect(guid)}
                  >
                    {title}
                  </HooxSeasonButton>
                );
              })}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey={`season-${seasonId}`}>
                {episodes.map((item, index) => (
                  <div
                    key={index}
                    className="row"
                    style={{
                      marginBottom: "20px",

                      minWidth: isMobile ? "" : "990px",
                    }}
                  >
                    {isMobile ? <Line /> : ""}
                    <div
                      className="col-12 col-md-5 col-lg-5"
                      style={{
                        aspectRatio: "16/9",
                        width: isMobile ? "100%" : "",
                        height: "auto",
                      }}
                    >
                      {/* <EventComponent item={item} listType={2} /> */}

                      <EpisodeImage src={item.posters[0].url} />
                    </div>
                    <div className="col-12 col-md-5 col-lg-6">
                      <div className="col-12 mt-1">
                        <span
                          style={{
                            color: "white",
                            fontSize: "25px",
                            fontFamily: "GilroyMedium",
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                      <div
                        className="col-12 text-clip"
                        style={{ color: "#c1b4e8", wordWrap: "break-word" }}
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontFamily: "GilroyLight",
                            lineHeight: "110%",
                          }}
                        >
                          {item.synopsisLn}
                        </p>
                      </div>

                      <Row>
                        <Col>
                          <p
                            style={{
                              color: "white",
                              fontSize: "20px",
                              fontFamily: "GilroyLight",
                            }}
                          >
                            {item.duration} dk.
                          </p>
                        </Col>
                        <Col>
                          <p
                            style={{
                              color: "white",
                              fontSize: "20px",
                              fontFamily: "GilroyLight",
                            }}
                          >
                            {item.definitions}
                          </p>
                        </Col>
                      </Row>

                      {/**
                      <Row style={{ marginBottom: "10px" }}>
                        <ProgressBar
                          percentage={
                            (item.bookmarkTime / item.durationInSeconds) * 100
                          }
                        ></ProgressBar>
                      </Row>
                       */}
                      <Row>
                        <ContentButtonGroup
                          usrRight={1}
                          onClick={() => handlePlayClick(item.guid)}
                        />
                      </Row>
                    </div>
                  </div>
                ))}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default EpisodeViewer;
