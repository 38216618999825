import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { useAuth } from "../components/AuthContext";
import { useMediaQuery } from "react-responsive";
import { getNewToken } from "../service/authService";
import styled from "styled-components";

export const SubsFormButtons = styled(`Button`)`
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

const PaymantFallback = () => {
  const type = useParams().type;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const titleText = () => {
    if (type === "success") {
      return "Ödeme işleminiz başarıyla tamamlandı.";
    } else if (type === "fail") {
      return "Ödeme işleminiz başarısız oldu.";
    } else {
      return "Ödeme sırasında bir hata oluştu";
    }
  };

  //const [isSubscriptionExist, setIsSubscriptionExist] = useState(false);
  //const [userName, setUserName] = useState("");
  //const [packageName, setPackageName] = useState("");
  //const [packageId, setPackageId] = useState("");
  //const [packagePrice, setPackagePrice] = useState("");
  //const [message, setMessage] = useState("");
  //const [transactionId, setTransactionId] = useState("");

  //const { user } = useAuth();
  //const { isLoggedIn } = user;
  //const { setUser } = useAuth();

  const queryParams = new URLSearchParams(window.location.search);

  const customerName = queryParams.get("cn");
  const cnDecodedValue = decodeURIComponent(customerName);

  const packageName = queryParams.get("pn");
  const pnDecodedValue = decodeURIComponent(packageName);

  const campaignName = queryParams.get("can");
  const canDecodedValue = decodeURIComponent(campaignName);

  const ccParam = queryParams.get("cc");
  const packagePriceParam = queryParams.get("pp");
  const packageIdParam = queryParams.get("pi");
  const transactionId = queryParams.get("ti");
  const tkn = queryParams.get("t");

  const message = queryParams.get("message");

  const { user, setUser } = useAuth();

  useEffect(() => {
    //console.log("type:", type);
    //console.log("customerName:", customerName);
    if (type === "success") {
      //console.log("getNewTokenCall");
      getNewToken(tkn).then((response) => {
        console.log("PaymantFallback.getNewToken.response", response);
        if (response.code === 200) {
          //localStorage.setItem("authToken", response.data);
          setUser({
            userName: response.data.data.username,
            isLoggedIn: true,
            //userId: response.data.data.id,
            //customerId: response.data.data.customerId
            guid: response.data.data.guid,
          });
        } else {
          console.log("error token alınamadı.");
        }
      });
    }

    /*
    const timer = setTimeout(() => {
      if (isLoggedIn) {
        if (type === "success") {
          
          if (ccParam != 1) {
            logout().then(() => {
              setUser({ userName: null, isLoggedIn: false });
              localStorage.clear();
              navigate("/user/login");
            });
          } else {
            navigate("/user-details");
          }
          
        } else {
          //navigate("/home");
        }
      }
    }, 7000);
    
    return () => clearTimeout(timer);

    */
  }, []);

  /*
  useEffect(() => {
    if (type === "success") {
      validateSubscription(packageExternalId, customerGuid).then((response) => {
        //console.log("validateSubscription.response:", response);
        if (response?.code === 200) {
          setIsSubscriptionExist(response.data?.isExist);
          if (response.data?.isExist) {
            //setUserName(response.data?.customerName || "");
            //setPackageName(response.data?.productName);
            setPackageId(response.data?.productId || "");
            setPackagePrice(response.data?.productPrice || "");
            setTransactionId(response.data?.transaction_id || "");
            //datalayerPush();
          }
        } else {
          //console.log("message:", response?.message);
          setMessage(response?.message || "");
        }

        // TODO: select activeIndex logic
      });
    }
  }, [customerGuid]);
*/

  /*
  useEffect(() => {
    if (packageExternalId === "edu") {
      <script>esk('track', 'ogrenci');</script>;
    }
    <script>esk('track', 'ikicihaz');</script>;
  }, []);
*/

  useEffect(() => {
    if (type === "success") {
      if (transactionId) {
        //console.log("productPrice:", packagePrice);
        //console.log("transactionId:", transactionId);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "TRY",
            value: packagePriceParam,
            transaction_id: transactionId,
            items: [
              {
                item_name: packageName,
                item_id: packageIdParam,
                price: packagePriceParam,
                quantity: "1",
              },
            ],
          },
        });
      }
    }
  }, [transactionId]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ödeme {type === "success" ? "Başarılı" : "Başarısız"}</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. "
          />
        </Helmet>
      </div>

      <div
        style={{
          minHeight: "80vh",
          backgroundSize: "cover",
          backgroundImage: isMobile ? "" : `url(/assets/img/hoox_girisv3.png)`,
          backgroundPosition: "right",
        }}
      >
        <div
          className="container"
          style={{ marginTop: isMobile ? "" : "15vh", marginBottom: "20vh" }}
        >
          <Container>
            <Header />
            <Row style={{ margin: "10px", textAlign: "center" }}>
              <Col style={{ textAlign: "center" }}>
                <h1
                  style={{
                    fontSize: isMobile ? "2em" : "3em",
                    marginBottom: "20px",
                    marginTop: isMobile ? "20%" : "10%",
                    color: "#12d6df",
                  }}
                >
                  {titleText()}
                </h1>{" "}
                {transactionId && (
                  <>
                    <p style={{ fontSize: "1.7em", fontFamily: "GilroyLight" }}>
                      Merhaba {cnDecodedValue}
                    </p>
                    <p style={{ fontSize: "1.5em", fontFamily: "GilroyLight" }}>
                      {packageName} paket aboneliğin başarılı şekilde
                      {ccParam != 1 ? " tamamlandı." : " güncellendi."}
                    </p>
                    {campaignName && (
                      <>
                        <p
                          style={{
                            fontSize: "1em",
                            fontFamily: "GilroyLight",
                            marginTop: "30px",
                          }}
                        >
                          {canDecodedValue} kampanyasından faydalandınız.
                        </p>
                      </>
                    )}
                  </>
                )}
                {message && (
                  <p style={{ fontSize: "1.5em", fontFamily: "GilroyLight" }}>
                    {message}
                  </p>
                )}
                {type === "success" ? (
                  <>
                    <p
                      style={{
                        fontSize: "1.5em",
                        fontFamily: "GilroyLight",
                        marginTop: "30px",
                      }}
                    >
                      {ccParam != 1
                        ? "Birbirinden eğlenceli içeriklerimizi izlemeye başlayabilirsin."
                        : "Aboneliğiniz devam ediyor."}
                    </p>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        fontSize: "1.5em",
                        fontFamily: "GilroyLight",
                        marginTop: "30px",
                      }}
                    >
                      {ccParam != 1 ? "Abonelik işleminiz tamamlanamadı." : ""}
                    </p>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "center" }}>
              <Col>
                {type === "success" ? (
                  <>
                    {/**
                    <p>
                      {ccParam != 1
                        ? "Giriş sayfasına yönlendiriliyorsunuz.."
                        : "Hesabım sayfasına yönlendiriliyorsunuz."}
                    </p>
 */}
                    <SubsFormButtons
                      className="btn btn-primary cancelButton"
                      style={{
                        fontFamily: "GilroyLight",
                        fontSize: "1.5em",
                      }}
                      type="button"
                      onClick={() => navigate("/home")}
                    >
                      İzlemeye Başla
                    </SubsFormButtons>
                  </>
                ) : (
                  <>
                    <Button
                      variant="secondary"
                      style={{
                        fontFamily: "GilroyLight",
                        fontSize: "1.2em",
                        backgroundColor: "transparent",
                        padding: "10px 35px 10px 35px",
                      }}
                      onClick={
                        () => navigate("/home")
                        //navigate(ccParam != 1 ? "/user/login" : "/user-details")
                      }
                    >
                      Ana Sayfa
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PaymantFallback;
