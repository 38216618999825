import React from "react";

export default function MatomoTracking() {
  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://cdn.matomo.cloud/hoox.matomo.cloud/container_JC7Ghd5k.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return null;
}
