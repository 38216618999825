import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
// import VideoPlayer from '../components/VideoPlayerComp';
import VideoPlayer from "../components/player/VideoPlayer";

function VideoPage() {
  //const content = useSelector((state) => state.content);

  const [show, setShow] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const navigate = useNavigate();

  const onHide = () => {
    setShow(false);
    navigate(-1);
    //navigate("");
  };

  return (
    <Modal show={show} fullscreen={true} onHide={onHide}>
      <Modal.Header style={styles.header} closeButton={showButton} />
      <Modal.Body style={styles.body}>
        {<VideoPlayer setCloseButton={setShowButton} />}
      </Modal.Body>
    </Modal>
  );
}

export default VideoPage;

const styles = {
  header: {
    border: "0px",
    zIndex: "2",
    height: "10vh",
    position: "absolute",
    top: 44,
    right: 44,
  },
  body: {
    backgroundColor: "black",
    padding: 16,
  },
};
