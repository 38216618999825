import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import DiscoverButton from "../components/DiscoverButton";
import { Carousel, Row, Col, Button, Container } from "react-bootstrap";
import {
  ProductPageTitle,
  ProductBox,
  ProductTitle,
  ProductDescription,
  ProductPrice,
} from "./ProductSelection/ProductSelection.styles";
import { getLandingPageContents, getProducts } from "../service/contentService";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import MatomoTracking from "../components/MatomoTracking";
import { useAuth } from "../components/AuthContext";
import styled from "styled-components";

const CarouselStyled = styled(Carousel)`
  & .carousel-control-prev,
  & .carousel-control-next {
    justify-content: flex-start;
    height: 40%;
    margin: auto;
    width: auto;
    background-color: rgba(55, 55, 55, 0.5);
    opacity: 1;
  }

  & .carousel-control-prev:hover,
  & .carousel-control-next:hover {
    background-color: rgba(55, 55, 55, 0.8);
  }

  & .carousel-control-next-icon,
  & .carousel-control-prev-icon {
    color: #fff;
  }

  & .carousel-indicators {
    margin-bottom: ${(props) => (props.ismobile ? "0px" : null)};
    button {
      width: ${(props) => (props.ismobile ? "10px" : "15px")};
      height: ${(props) => (props.ismobile ? "10px" : "15px")};
      border-radius: 50%;
    }

    .active {
      background-color: #bb00bb;
    }
  }
`;

function Welcome() {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [contents, setContents] = useState(null);
  //const isDesktopOrLaptop = useMediaQuery({query: "(min-width: 630px)",});
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const windowWidth = window.innerWidth;

  const { user } = useAuth();

  const { isLoggedIn, guid } = user;

  useEffect(() => {
    getProducts().then((response) => {
      setProducts(response.data);
      // TODO: select activeIndex logic
    });
  }, []);

  useEffect(() => {
    getLandingPageContents().then((response) => {
      //console.log("landing page contents:", response);
      if (response.status == 200) {
        setContents(response.data);
      }
    });
  }, []);

  useEffect(() => {
    document.body.style.marginTop = 0;

    return () => {
      document.body.style.marginTop = null;
    };
  }, []);

  return (
    <>
      <MatomoTracking />
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hoox</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. Keşfetmekten keyif alınacak..."
          />
        </Helmet>
      </div>

      <CarouselStyled
        controls={contents && contents.length > 1}
        ismobile={isMobile ? isMobile : undefined}
      >
        {contents &&
          contents.map((content) => (
            <Carousel.Item key={content.id}>
              <Link to={`/${content.urlName}/${content.guid}`}>
                <img
                  className="bd-placeholder-img"
                  style={{ height: "auto", width: "100%" }}
                  src={content.posters[0].url}
                  alt=""
                />
              </Link>
            </Carousel.Item>
          ))}
      </CarouselStyled>

      {!isMobile && (
        <>
          <div
            className="welcome"
            // style={{ backgroundImage: `url(/assets/img/background.png)` }}
          >
            <div style={{ marginBottom: "260px" }}>
              <img
                style={{ width: "100%" }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/52cb6edc-da62-47db-60f6-db3684626400/public"
                }
                alt=""
              />
            </div>
            <div className="filler">
              <div className="text-container">
                <h1>İzlenecek</h1>
                <h1>çok şey var!</h1>
                <span>
                  Kapuçin Maymunlarının her şeyin aslında para olmadığını nasıl
                  öğrendiklerinden, zamanında içinde sirk bile olan o meșhur
                  Beyoğlu pasajınin hikayesine kadar izlenecek çok şey var!
                </span>
                <div id="explore-button">
                  <DiscoverButton
                    showHoox={false}
                    //onButtonClick={() => navigate("/user/register")}
                    onButtonClick={() => navigate("/home")}
                    style={{
                      left: windowWidth / 10.5,
                      top: windowWidth / 3.7,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="carousel">
            <Carousel controls={false}>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/06e5aeb7-9fa7-4e62-c625-1b1082aeed00/public"
                  }
                  alt=""
                />
                <DiscoverButton
                  //onButtonClick={() => navigate("/user/register")}
                  onButtonClick={() =>
                    navigate("/mavirota/56DFAB44-93BC-4976-A245-95D639C0D4A4")
                  }
                  style={{
                    position: "absolute",
                    left: windowWidth / 8,
                    top: windowWidth / 5,
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/274f4c76-86e8-4a7b-9135-1e26a1edba00/public"
                  }
                  alt=""
                />
                <DiscoverButton
                  onButtonClick={() =>
                    navigate("/makyaj101/4CFECA61-152B-4B69-A0B0-01395EA0B49F")
                  }
                  style={{
                    position: "absolute",
                    left: windowWidth / 8,
                    top: windowWidth / 5,
                  }}
                />
              </Carousel.Item>
            </Carousel>
          </div>

          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "50%",
                  marginTop: "280px",
                  marginBottom: "50px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/e00feade-4a70-4ae5-718d-231b954c7700/public"
                }
                alt=""
              />
            </div>
          </Row>

          <div className="text-container">
            <h1>Mars için kesin bir söz söyleyemeyiz.</h1>
            <span>
              Bir tablet, bir telefon, bir televizyon ve bir de bilgisayar hep
              birlikte film izlemek için yola çıkmışlar... Tabii ki internet
              olan her yerden istediğin zaman izlersin. Ama Mars'a fiber alt
              yapı gitti mi henüz bilmiyoruz.
            </span>
          </div>
          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "100%",
                  marginTop: "250px",
                  marginBottom: "50px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/a16eb849-415b-4d5b-61b6-1c6ee8b29100/public"
                  //"https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/13cbe8a8-d2fc-4e0a-ee24-aa0743723a00/public"
                }
                alt=""
              />
            </div>
          </Row>

          <div className="text-container">
            <h1>Çay molaları şimdi daha bir anlamlı!</h1>
            <span>
              Çünkü Hoox'ta çay molalarında bile bir bölümünü bitirebileceğin
              kısa yapımlar var.
            </span>
          </div>
          <div className="product-selection">
            <Row style={{ marginTop: "200px" }}>
              <Col>
                <ProductPageTitle className="text-center m-3">
                  SİZE UYGUN PAKETİ SEÇİN
                </ProductPageTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container>
                  <Row className="justify-content-center">
                    {products &&
                      products.slice(0, 3).map((product, index) => (
                        <Col md={3} key={index}>
                          <ProductBox
                            key={index}
                            className="text-center p-4"
                            onClick={() =>
                              isLoggedIn
                                ? navigate("/user/product-selection/" + guid)
                                : navigate("user/register")
                            }
                          >
                            <div className="m-4">
                              <img
                                style={{ height: "auto", width: "40%" }}
                                src={product.imageUrl}
                              />
                            </div>
                            <ProductTitle>{product.name}</ProductTitle>
                            <hr
                              style={{
                                color: "#12d6df",
                                opacity: "1",
                                margin: "20px",
                              }}
                            />
                            <ProductDescription
                              style={{ textAlign: "left", margin: "20px" }}
                            >
                              {product.description
                                .split("|")
                                .map((item, index) => (
                                  <p key={index} style={{ margin: "2px" }}>
                                    <span style={{ color: "#bc00bc" }}>• </span>
                                    {`${item}`}
                                  </p>
                                ))}
                            </ProductDescription>

                            <ProductPrice>
                              {
                                product.priceWithTax
                                  .toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                  .split(",")[0]
                              }
                              ,
                              <small>
                                {
                                  product.priceWithTax
                                    .toLocaleString("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                    .split(",")[1]
                                }
                              </small>{" "}
                              ₺
                            </ProductPrice>
                          </ProductBox>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-4">
                <Button
                  style={{ color: "#fff", width: "20vh" }}
                  className="btn btn-primary checkOutButton"
                  type="button"
                  onClick={() =>
                    isLoggedIn
                      ? navigate("/user/product-selection/" + guid)
                      : navigate("user/register")
                  }
                >
                  {isLoggedIn ? "ABONE OL" : "KAYIT OL"}
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}

      {/*  
      Mobile Screen Codes
      */}

      {isMobile && (
        <>
          <div
            className="welcome"
            // style={{ backgroundImage: `url(/assets/img/background.png)` }}
          >
            <div className="crop">
              <img
                style={{ width: "100%" }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/c33cfb28-d14d-4cb1-a9d9-85546caa3a00/public"
                }
                alt=""
              />
            </div>
            <div className="filler-responsive">
              <div className="text-container">
                <h1>İzlenecek</h1>
                <h1>çok şey var!</h1>
                <span>
                  Kapuçin Maymunları'nın her şeyin aslında para olmadığını nasıl
                  öğrendiklerinden, zamanında içinde sirk bile olan o meșhur
                  Beyoğlu pasajının hikayesine kadar izlenecek çok şey var!
                </span>
              </div>
            </div>
          </div>
          <Row className="mb-2 mr-0">
            <Col className="text-center mt-1">
              <Button
                style={{ color: "#fff", width: "22vh" }}
                className="btn btn-primary checkOutButton mt-3 mb-1"
                type="button"
                onClick={() => navigate("home")}
              >
                KEŞFET
              </Button>
            </Col>
          </Row>
          <Row className="mb-5 mr-0">
            <Col className="text-center mt-1">
              <Button
                style={{ color: "#fff", width: "22vh" }}
                className="btn btn-primary checkOutButton mt-3 mb-1"
                type="button"
                onClick={() => navigate("user/register")}
              >
                HEMEN KAYIT OL
              </Button>
            </Col>
          </Row>
          <div className="carousel crop-carousel">
            <Carousel controls={false}>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/06e5aeb7-9fa7-4e62-c625-1b1082aeed00/public"
                  }
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/274f4c76-86e8-4a7b-9135-1e26a1edba00/public"
                  }
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <Row>
            <DiscoverButton
              onButtonClick={() => navigate("/user/register")}
              style={{}}
              isMobile
            />
          </Row>
          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "100%",
                  marginTop: "80px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/e00feade-4a70-4ae5-718d-231b954c7700/public"
                }
                alt=""
              />
            </div>
          </Row>
          <div className="filler-responsive">
            <div className="text-container">
              <h1>Mars için kesin bir söz söyleyemeyiz.</h1>
              <span>
                Bir tablet, bir telefon, bir televizyon ve bir de bilgisayar hep
                birlikte film izlemek için yola çıkmışlar... Tabii ki internet
                olan her yerden istediğin zaman izlersin. Ama Mars'a fiber
                altyapı gitti mi henüz bilmiyoruz.
              </span>
            </div>
          </div>
          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "100%",
                  marginTop: "120px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/4aa6673f-5321-42c0-625a-b21c7f6c1400/public"
                }
                alt=""
              />
            </div>
          </Row>
          <div className="filler-responsive">
            <div className="text-container">
              <h1>Çay molaları şimdi daha bir anlamlı!</h1>
              <span>
                Çünkü Hoox'ta çay molalarında bile bir bölümünü bitirebileceğin
                kısa yapımlar var.
              </span>
            </div>
          </div>
          <div className="product-selection">
            <Row style={{ marginTop: "144px" }}>
              <Col>
                <ProductPageTitle className="text-center m-3">
                  SİZE UYGUN PAKETİ SEÇİN
                </ProductPageTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container>
                  <Row className="justify-content-center">
                    {products &&
                      products.slice(0, 3).map((product, index) => (
                        <Col md={4}>
                          <ProductBox
                            key={index}
                            className="text-center p-4 mb-5"
                            onClick={() => navigate("user/register")}
                          >
                            <div className="m-4">
                              <img
                                style={{ height: "auto", width: "40%" }}
                                src={product.imageUrl}
                              />
                            </div>
                            <ProductTitle>{product.name}</ProductTitle>
                            <hr
                              style={{
                                color: "#12d6df",
                                opacity: "1",
                                margin: "20px",
                              }}
                            />
                            <ProductDescription
                              style={{ textAlign: "left", margin: "20px" }}
                            >
                              {product.description
                                .split("|")
                                .map((item, index) => (
                                  <p key={index} style={{ margin: "2px" }}>
                                    <span style={{ color: "#bc00bc" }}>• </span>
                                    {`${item}`}
                                  </p>
                                ))}
                            </ProductDescription>

                            <ProductPrice>
                              {
                                product.priceWithTax
                                  .toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                  .split(",")[0]
                              }
                              ,
                              <small>
                                {
                                  product.priceWithTax
                                    .toLocaleString("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                    .split(",")[1]
                                }
                              </small>{" "}
                              ₺
                            </ProductPrice>
                          </ProductBox>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3 d-flex justify-content-center">
                <button
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #bc00bc",
                    width: "180px",
                    height: "40px",
                    backgroundColor: "transparent",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  className="btn btn-secondary outline-primary"
                  type="button"
                  onClick={() =>
                    isLoggedIn
                      ? navigate("/user/product-selection/" + guid)
                      : navigate("user/register")
                  }
                >
                  {isLoggedIn ? "ABONE OL" : ""}
                </button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default Welcome;
