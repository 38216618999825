import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getContentDetail,
  getContentDetailByGuid,
  getEpisodes,
  getEpisodesByGuid,
} from "../../service/contentService";

export const fetchContent = createAsyncThunk(
  "content/getContent",
  async (guid) => {
    const response = await getContentDetailByGuid(guid);
    return response.data;
  }
);

export const fetchContentByGuid = createAsyncThunk(
  "content/getContentByGuid",
  async (guid) => {
    const response = await getContentDetailByGuid(guid);
    return response.data;
  }
);

export const fetchEpisode = createAsyncThunk(
  "content/getEpisode",
  async (guid) => {
    const response = await getContentDetailByGuid(guid);
    return response.data;
  }
);

export const fetchEpisodes = createAsyncThunk(
  "content/getEpisodes",
  async (guid) => {
    const response = await getEpisodesByGuid(guid);
    return response.data;
  }
);

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    detail: {},
    episodes: [],
    watching: {},
  },
  reducers: {
    releaseContent: (state) => {
      state.detail = {};
      state.episodes = [];
    },
    selectWatchingContent: (state, action) => {
      state.watching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = "idle";
        state.watching = action.payload;
        state.detail = action.payload;
      })
      .addCase(fetchContent.rejected, (state, action) => {
        const { error } = action;
        state.status = "failed";
        state.error = error;
      })
      .addCase(fetchEpisode.fulfilled, (state, action) => {
        //console.log("fetchEpisode fulfilled:", action.payload);
        //console.log("Before-state.watching:", state.watching);
        state.status = "idle";
        state.watching = action.payload;
        //console.log("After-state.watching:", state.watching);
      })
      .addCase(fetchEpisodes.fulfilled, (state, action) => {
        state.episodes = action.payload;
      })
      .addCase(fetchContentByGuid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContentByGuid.fulfilled, (state, action) => {
        state.status = "idle";
        state.watching = action.payload;
        state.detail = action.payload;
      })
      .addCase(fetchContentByGuid.rejected, (state, action) => {
        const { error } = action;
        state.status = "failed";
        state.error = error;
      });
  },
});

export const { releaseContent, selectWatchingContent } = contentSlice.actions;

export default contentSlice.reducer;
