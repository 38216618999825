import { Navbar, Nav, Button, Form, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import HooxModal, { HOOX_MODAL } from "../HooxModal";
import useModal from "../hooks/useModal";

const GuestUserNav = ({ setExpanded, expanded }) => {
  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingLogin, toggle: toggleSuccessLogin } = useModal();

  return (
    <Navbar.Collapse
      id="responsive-navbar-nav"
      style={{
        backgroundColor: "transparent",
      }}
    >
      <Nav
        className="ms-auto mb-2 my-lg-0"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <HooxModal
          isShowing={isShowingSuccess}
          onlyTitle="Nasıl Üye Olabilirim?"
          title="Nasıl Üye Olabilirim?"
        >
          <div className="video-responsive-welcome">
            <iframe
              height={800}
              width={600}
              src="https://www.youtube.com/embed/orrGDfP5kUM?rel=0"
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>

          <Button variant="secondary" onClick={toggleSuccessModal}>
            Kapat
          </Button>
        </HooxModal>
        {/*}
        <Link
          className="nav-link d-flex justify-content-end"
          onClick={toggleSuccessModal}
        >
          <span className="howto-button">Nasıl Üye Olabilirim?</span>
        </Link>
      */}

        {
          <Link
            className="nav-link d-flex justify-content-end"
            to="/home"
            onClick={() => setExpanded(expanded ? false : expanded)}
          >
            <span className="register-button">KEŞFET</span>
          </Link>
        }

        <Link
          className="nav-link d-flex justify-content-end"
          to="/user/login"
          onClick={() => setExpanded(expanded ? false : expanded)}
        >
          <span className="login-button">GİRİŞ YAP</span>
        </Link>
      </Nav>
    </Navbar.Collapse>
  );
};

export default GuestUserNav;
