import { useState } from "react";
import {
  Button,
  Col,
  FormControl,
  Modal,
  ModalBody,
  ModalTitle,
  Row,
} from "react-bootstrap";
import { confirmEmail } from "../service/customerService";
import { useNavigate } from "react-router";
import { login } from "../service/authService";
import { useAuth } from "./AuthContext";

const EmailConfirmaitonCodePopup = ({ isShowing, hide, email, pass }) => {
  const [confirmCode, setConfirmCode] = useState(undefined);
  //const [isLoading, setIsLoading] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);

  const [resultMessage, setResultMessage] = useState(undefined);

  //const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const navigate = useNavigate();

  const { setUser } = useAuth();

  const setCode = (e) => {
    setConfirmCode(e);
    //console.log("confirmCode:", confirmCode);
  };

  const sendCode = (event) => {
    setIsSuccess(true);
    setResultMessage("");
    event.preventDefault();
    //console.log("confirmCode:", confirmCode);
    if (confirmCode === undefined) {
      //return;
    }
    confirmEmail(confirmCode).then((response) => {
      //console.log("confirm.repsonse:", response);
      if (response) {
        if (response.data?.code === 200) {
          const userId = response.data?.data;
          if (userId) {
            //console.log("user_id. serbest:", userId);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "confirm",
              userId: userId,
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "sign_up",
              userId: userId,
            });
          }

          setResultMessage(response.data?.message);

          login(email, pass)
            .then((response) => {
              if (
                response !== undefined &&
                response.data.code !== undefined &&
                response.data.code === 200
              ) {
                //setUserGuid(response.data.data.guid);
                if (response.data.data.token !== undefined) {
                  navigate("/abonelik/paketler");
                }
                setUser({
                  userName: response.data.data.username,
                  isLoggedIn: true,
                  guid: response.data.data.guid,
                });
              }
            })
            .catch((err) => {
              //SetError("Beklenmeyen bir hata oluştu.");
              //toggleErrorModal();
              //setIsLoading(false);
            });

          //setTimeout(() => {
          //  navigate("/user/login");
          //}, 4000);
        } else {
          setIsSuccess(false);
          setResultMessage(response.data?.message);
        }
      } else {
        setIsSuccess(false);
        setResultMessage("Bir sorun oluştu.");
      }
    });
  };

  return (
    <>
      <Modal
        show={isShowing}
        onHide={hide}
        dialogClassName={"modal-40w"}
        backdrop={"static"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      >
        <ModalTitle className="hoox-text" style={{ marginTop: "20px" }}>
          Eposta Doğrulama
        </ModalTitle>
        <ModalBody className="hoox-text">
          <p>
            Kayıt olduğunuz eposta adresinize <br />
            gelen kodu girerek "Gönder" 'e basınız.
            <br />
            {email}
          </p>
          <Row
            className="d-flex justify-content-center"
            style={{ margin: "20px" }}
          >
            <Col sm="4">
              <FormControl
                type="text"
                id="confirmCode"
                onChange={(e) => setCode(e.target.value)}
                required
                placeholder="Onay Kodu"
                style={{
                  fontSize: "1.1rem",
                  width: "60%",
                  textAlign: "center",
                }}
                disabled={isSucces}
              />
            </Col>
          </Row>
          {resultMessage && (
            <Row className="d-flex justify-content-center">
              <div
                className="d-flex justify-content-center"
                style={{
                  backgroundColor: isSucces ? "#12f80057" : "#ff000078",
                  width: "60%",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              >
                {isSucces === true ? (
                  ""
                ) : (
                  <i
                    className="bi bi-exclamation-circle-fill"
                    style={{ marginRight: "5px" }}
                  ></i>
                )}
                {resultMessage}
              </div>
            </Row>
          )}

          <Row>
            <Col sm="12">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    marginTop: "20px",
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="btn btn-primary checkOutButton"
                  onClick={sendCode}
                  disabled={isSucces}
                >
                  {isSucces ? <div className="spinner"></div> : "GÖNDER"}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmailConfirmaitonCodePopup;
