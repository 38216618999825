import styled from "styled-components";
import React, { useState } from "react";
import HooxModal, { HOOX_MODAL } from "./HooxModal";
import useModal from "../components/hooks/useModal";
import ReactMarkdown from "react-markdown";
import { Button, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { ReactComponent as FacebookLogo } from "../assets/svg/hoox_SM_fb.svg";
import { ReactComponent as InstagramLogo } from "../assets/svg/hoox_SM_ins.svg";
import { ReactComponent as YoutubeLogo } from "../assets/svg/hoox_SM_yut.svg";
import { ReactComponent as XLogo } from "../assets/svg/hoox_SM_x.svg";
import { ReactComponent as TikTokLogo } from "../assets/svg/hoox-tiktok.svg";

const HooxFooterLink = styled.a`
  color: #747788;
  font-family: "GilroyLight";
  text-decoration: none;
  margin-bottom: 1rem;
  display: block;

  &:hover {
    color: white;
  }
`;

const HooxFooterHeader = styled.h4`
  font-family: "GilroyMedium";
  color: #747788;
`;

const HooxFooterBody = styled.div`
  padding-left: ${(props) => (props.isMobile ? "10px" : "90px")};
  padding-right: ${(props) => (props.isMobile ? "" : "90px")};
`;

function Footer() {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const navigate = useNavigate();

  const [contract, setContract] = useState(undefined);
  const [aboutUs, setAboutUs] = useState(undefined);
  const [modalData, setModalData] = useState(undefined);
  const { isShowing: isShowingContract, toggle: toggleContractModal } =
    useModal();
  const { isShowing: isShowingAboutUs, toggle: toggleAboutUsModal } =
    useModal();

  /*
  const handleClick = (data) => {
    setModalData(data);
    toggleContractModal();
  };

  useEffect(() => {
    if (contract === undefined) {
      getContracts()
        .then((response) => {
          //console.log(response.data);
          //setContract(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [contract]);
*/

  /* useEffect(() => {
    if (aboutUs === undefined) {
      getAboutUs()
        .then((response) => {
          //console.log(JSON.parse(response.data));
          setAboutUs(JSON.parse(response.data));
        })
        .catch((error) => console.log(error));
    }
  }, [aboutUs]); */

  return (
    <div className="main-footer mb-4" style={{ marginTop: 50 }}>
      <div className="f-top">
        <HooxFooterBody className="" isMobile={isMobile}>
          <div>
            <hr style={{ opacity: "0.5", marginBottom: "30px" }} />
          </div>
          <Row className="d-flex justify-content-between">
            <Row className="col-12 col-lg-5 mb-4">
              <Col className="col-12 col-lg-8">
                <Row className="row">
                  <img
                    alt=""
                    src="/assets/img/white-transparent.png"
                    style={{ height: 40, width: "auto", marginBottom: "10px" }}
                  />
                  <h6 style={{ fontSize: 12, color: "#A098B7" }}>
                    Muud Medya Dijital Yayıncılık A.Ş.{" "}
                  </h6>
                </Row>
                <Row>
                  <Col
                    className="col-12"
                    style={{
                      fontSize: 12,
                      color: "#A098B7",
                      marginTop: "50px",
                      marginBottom: "10px",
                    }}
                  >
                    Hoox her yerde yanınızda olsun.
                  </Col>
                  <Col className="col-6" style={{ display: "flex" }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.muud.hoox"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ maxWidth: 150, marginRight: "10px" }}
                        src="/assets/img/googlestore.png"
                        alt="Google Market Place"
                      />
                    </a>

                    <a
                      href="https://apps.apple.com/tr/app/hoox/id1662631706"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ maxWidth: 150 }}
                        src="/assets/img/applestore.png"
                        alt="Apple App Store"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Col className="col-12 col-lg-3">
              <Row className="align-items-start">
                <Col className="col-9 col-md-9 mb-9">
                  <HooxFooterHeader>Hoox </HooxFooterHeader>
                  <HooxFooterLink onClick={() => navigate("/AboutUs")}>
                    Hakkımızda
                  </HooxFooterLink>
                  <HooxFooterLink onClick={() => navigate("/Kvkk")}>
                    KVKK Aydınlatma Metni
                  </HooxFooterLink>

                  <HooxFooterLink
                    onClick={() => navigate("/MembershipAgreement")}
                  >
                    Üyelik Sözleşmesi{" "}
                  </HooxFooterLink>

                  <HooxFooterLink onClick={() => navigate("/CookiePolicy")}>
                    Çerez Politikası
                  </HooxFooterLink>

                  <HooxFooterLink
                    onClick={() => navigate("/DistanceSellingContract")}
                  >
                    Mesafeli Satış Sözleşmesi
                  </HooxFooterLink>

                  {/* <HooxFooterLink onClick={() => handleClick(null)} >Güvenli Alışveriş</HooxFooterLink> */}
                  <HooxFooterLink onClick={() => navigate("/TermsOfUse")}>
                    Kullanım Koşulları
                  </HooxFooterLink>
                </Col>
                <Col className="col-3 col-md-3 mb-3 justify-content-md-center">
                  <HooxFooterLink
                    target={"_blank"}
                    className="row"
                    style={{ textDecoration: "none" }}
                    href="https://www.facebook.com/profile.php?id=100092460043853"
                  >
                    <Col className="col-10" style={{ width: "60px" }}>
                      <FacebookLogo />
                    </Col>
                  </HooxFooterLink>
                  <HooxFooterLink
                    target={"_blank"}
                    className="row"
                    style={{ textDecoration: "none" }}
                    href="https://www.instagram.com/hoox.tv/"
                  >
                    <Col className="col-10" style={{ width: "60px" }}>
                      <InstagramLogo />
                    </Col>
                  </HooxFooterLink>
                  <HooxFooterLink
                    target={"_blank"}
                    className="row"
                    style={{ textDecoration: "none" }}
                    href="https://www.youtube.com/@hooxtv"
                  >
                    <Col className="col-10" style={{ width: "60px" }}>
                      <YoutubeLogo />
                    </Col>
                  </HooxFooterLink>
                  <HooxFooterLink
                    target={"_blank"}
                    className="row"
                    style={{ textDecoration: "none" }}
                    href="https://twitter.com/hoox_tv"
                  >
                    <Col className="col-10" style={{ width: "60px" }}>
                      <XLogo />
                    </Col>
                  </HooxFooterLink>
                  <HooxFooterLink
                    target={"_blank"}
                    className="row"
                    style={{ textDecoration: "none" }}
                    href="https://www.tiktok.com/@hoox.tv"
                  >
                    <Col className="col-10" style={{ width: "60px" }}>
                      <TikTokLogo />
                    </Col>
                  </HooxFooterLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </HooxFooterBody>
      </div>
      <div className="f-bottom" />
      <HooxModal
        type={HOOX_MODAL.TEXT}
        isShowing={isShowingContract}
        hide={toggleContractModal}
        style={{ padding: "4em" }}
      >
        <ReactMarkdown>{modalData}</ReactMarkdown>
        <Button variant="secondary" onClick={toggleContractModal}>
          Kapat
        </Button>
      </HooxModal>
      <HooxModal
        type={HOOX_MODAL.TEXT}
        isShowing={isShowingAboutUs}
        hide={toggleAboutUsModal}
        style={{ padding: "4em" }}
      >
        <h1>{aboutUs?.title}</h1>
        <p>{aboutUs?.body}</p>
        <p>{aboutUs?.footer}</p>
        <Button variant="secondary" onClick={toggleAboutUsModal}>
          Kapat
        </Button>
      </HooxModal>
    </div>
  );
}

export default Footer;
