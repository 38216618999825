import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Listing from "./pages/Listing";
import ContentDetail from "./pages/content/ContentDetail";
import LoginRegister from "./pages/Register";
import UserDetails from "./pages/UserDetails";
import Favorites from "./pages/Favorites";
import VideoPage2 from "./pages/VideoPage";
import Layout from "./components/Layout";
import { AuthContext } from "./components/AuthContext";
import Login from "./pages/Login";
import VideoPage from "./pages/VideoPage";
import Welcome from "./pages/Welcome";
import ProductSelection from "./pages/ProductSelection";
import PaymentDirect from "./pages/PaymentDirect";
import PaymentFallback from "./pages/PaymentFallback";
import CreationSuccess from "./pages/CreationSuccess";
import CreationFailed from "./pages/CreationFailed";
import CookieModal from "./components/CookieModal";
import { EskimiTrackWrapper } from "./components/hoc/EskimiTrackWrapper";
import { initGoogleAnalytic } from "./service/googleAnalyticsService";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/contracts/AboutUs";
import Kvkk from "./pages/contracts/Kvkk";
import MembershipAgreement from "./pages/contracts/MembershipAgreement";
import CookiePolicy from "./pages/contracts/CookiePolicy";
import DistanceSellingContract from "./pages/contracts/DistanceSellingContract";
import TermsOfUse from "./pages/contracts/TermsOfUse";
import ResetPassword from "./pages/ResetPassword";
import TrackPageView from "./components/TrackPageView";
import LandingPage from "./pages/LandingPage";
import IysRed from "./pages/IysRed";
import PackageSelection from "./pages/PackageSelection";
import CampaignWelcome from "./pages/CampaignWelcome";

function App() {
  const [user, setUser] = useState({
    userName: localStorage.getItem("userName"),
    isLoggedIn: localStorage.getItem("isUser") === "true",
    guid: localStorage.getItem("guid"),
  });

  const userProviderValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  useEffect(() => {
    initGoogleAnalytic();
  }, []);

  /*
  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://cdn.matomo.cloud/hoox.matomo.cloud/container_JC7Ghd5k.js";
    s.parentNode.insertBefore(g, s);
  }, []);
*/

  return (
    <AuthContext.Provider value={userProviderValue}>
      <Router>
        <TrackPageView />
        <Routes>
          <Route path="/imsk/:guid" element={<IysRed />}></Route>

          {/**
          <Route
            path="/payment/fail"
            element={
              <EskimiTrackWrapper>
                <PaymentFail />
              </EskimiTrackWrapper>
            }
          ></Route>
           */}
           <Route path="/prst/:type" element={<PaymentFallback />}></Route>
          <Route exact path="/" element={<Layout />}>
            {/* /paketler  sozlesme icinde kullanilmaktadir.*/}
            <Route path="/paketler" element={<ProductSelection />} />
            <Route path="/merhaba/:code" element={<CampaignWelcome />} />
            
            {
              //user && user.isLoggedIn ? (
              user ? (
                <>
                  <Route path="/" index element={<LandingPage />} />
                  <Route
                    path="/home"
                    element={
                      <EskimiTrackWrapper>
                        <Home />
                      </EskimiTrackWrapper>
                    }
                  >
                    <Route
                      path=":categoryName/:categoryId"
                      element={
                        <EskimiTrackWrapper>
                          <Home />
                        </EskimiTrackWrapper>
                      }
                    />
                  </Route>

                  <Route
                    path="/:contentUrl/:contentId"
                    element={<ContentDetail />}
                  >
                    <Route path="play" element={<VideoPage />} />
                  </Route>

                  <Route
                    path="/:contentUrl/:contentId/:code"
                    element={
                      <EskimiTrackWrapper>
                        <ContentDetail />
                      </EskimiTrackWrapper>
                    }
                  >
                    <Route path="play" element={<VideoPage />} />
                  </Route>

                  <Route
                    path="/arama"
                    element={
                      <EskimiTrackWrapper>
                        <Listing />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user/login"
                    element={
                      <EskimiTrackWrapper>
                        <Login />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user/register"
                    element={
                      <EskimiTrackWrapper>
                        <LoginRegister />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user-details"
                    element={
                      <EskimiTrackWrapper>
                        <UserDetails />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route path="/favorites" element={<Favorites />} />
                  {/**
                  <Route
                    path="/videotest"
                    element={
                        <VideoPage2 />
                    }
                  />
                   */}
                  <Route
                    path="/:labelUrl/:categoryId"
                    element={
                      <EskimiTrackWrapper>
                        <Listing />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user/product-selection/:guid"
                    element={
                      <EskimiTrackWrapper>
                        <ProductSelection />
                      </EskimiTrackWrapper>
                    }
                  />
                  {/* /paketler  sozlesme icinde kullanilmaktadir.*/}
                  <Route path="/paketler" element={<ProductSelection />} />
                  <Route
                    path="/abonelik/paketler"
                    element={<PackageSelection />}
                  />
                  <Route
                    path="/payment-direct/:guid/:packageId"
                    element={
                      <EskimiTrackWrapper>
                        <PaymentDirect />
                      </EskimiTrackWrapper>
                    }
                  />

                  <Route path="AboutUs" element={<AboutUs />} />
                  <Route path="Kvkk" element={<Kvkk />} />
                  <Route
                    path="MembershipAgreement"
                    element={<MembershipAgreement />}
                  />
                  <Route path="CookiePolicy" element={<CookiePolicy />} />
                  <Route
                    path="DistanceSellingContract"
                    element={<DistanceSellingContract />}
                  />
                  <Route path="TermsOfUse" element={<TermsOfUse />} />
                  <Route
                    path="/creation-success"
                    element={<CreationSuccess />}
                  />
                  <Route path="/creation-failed" element={<CreationFailed />} />
                  <Route
                    path="/rstpsw"
                    element={
                      <EskimiTrackWrapper>
                        <ResetPassword />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route path="*" element={<PageNotFound />} />
                </>
              ) : (
                <>
                  <Route
                    path="/"
                    index
                    element={
                      <EskimiTrackWrapper>
                        <Welcome />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user/login"
                    element={
                      <EskimiTrackWrapper>
                        <Login />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/user/register"
                    element={
                      <EskimiTrackWrapper>
                        <LoginRegister />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/payment-direct/:guid/:packageId"
                    element={
                      <EskimiTrackWrapper>
                        <PaymentDirect />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/payment-fallback/:type"
                    element={
                      <EskimiTrackWrapper>
                        <PaymentFallback />
                      </EskimiTrackWrapper>
                    }
                  ></Route>
                  <Route
                    path="/user/product-selection/:guid"
                    element={
                      <EskimiTrackWrapper>
                        <ProductSelection />
                      </EskimiTrackWrapper>
                    }
                  />
                  <Route
                    path="/creation-success"
                    element={<CreationSuccess />}
                  />
                  <Route path="/creation-failed" element={<CreationFailed />} />
                </>
              )
            }
          </Route>
        </Routes>
      </Router>

      <CookieModal />
    </AuthContext.Provider>
  );
}

export default App;
