import React, { useRef, useState, useEffect } from "react";
import useVdocipher from "../../hooks/use-vdocipher";
import VideoStatusUsingAPI from "./VideoStatusUsingApi";
import { useMediaQuery } from "react-responsive";

const ContentVideo = ({ content, setPlayer, isTrailerPlay }) => {
  const { loadVideo, isAPIReady } = useVdocipher();
  const videoContainerRef = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    if (isTrailerPlay && !videoRef) {
      addVideo();
    }

    if (!isTrailerPlay && videoRef) {
      removeVideo();
    }

    return () => {
      removeVideo();
    };
  }, [isTrailerPlay]);

  function removeVideo() {
    if (videoRef) {
      videoRef.remove();
      setVideoRef(null);
    }
    if (videoContainerRef.current) {
      videoContainerRef.current.innerHtml = "";
    }
  }

  function addVideo() {
    const video = loadVideo({
      otp: content.trailerPlayerOKey,
      playbackInfo: content.trailerPlayerPKey,
      container: videoContainerRef.current,
      configuration: {
        player: "u31audcQjv1Z8w6D",
        autoplay: true,
        controls: "off",
      },
    });

    setVideoRef(video);
  }

  return (
    <>
      <div
        className={isMobile ? "vdo-container-mobile" : "vdo-container"}
        ref={videoContainerRef}
      />
      <VideoStatusUsingAPI
        videoRef={videoRef}
        isAPIReady={isAPIReady}
        setPlayer={setPlayer}
      />
    </>
  );
};

export default ContentVideo;
