import { useEffect, useState } from "react";
import { Container, Nav, Navbar, Offcanvas, Row } from "react-bootstrap";

import "./header.css";
import GuestUserNav from "./GuestUserNav";
import AuthUserNav from "./AuthUserNav";
import { useAuth } from "../AuthContext";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import slugifier from "../../util/slugifer";
import { apiUrl } from "../../util/apiConfig";

const HooxLogoImg = styled.img`
  height: 50px;
  margin-left: ${(props) => (props.isMobile ? "" : "90px")};
`;

function Header() {
  const { user } = useAuth();
  const { isLoggedIn } = user;
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const { categories, selectedCategory } = useSelector((state) => state.hoox);

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      className="header"
      expand="md"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      style={{ backgroundColor: expanded ? "#142445d1" : "transparent" }}
    >
      <Container fluid>
        <Navbar.Brand href={isLoggedIn ? "/home" : "/"} className="logo">
          <HooxLogoImg
            src="/assets/img/whitelogo.png"
            className="d-inline-block align-top"
            alt="Hoox Logo"
            isMobile={isMobile}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="bg-white"
          aria-controls="responsive-navbar-nav"
        />

        {/*
        apiUrl == "https://muudon.com/api/" ? (
          <p style={{ color: "white" }}>"TEST SISTEMI"</p>
        ) : (
          ""
        )
          */}

        {location.pathname.startsWith("/home") &&
          categories &&
          categories.map((category) => {
            const { name, id } = category;
            return (
              <Link
                key={id}
                className="nav-link d-flex"
                style={{ paddingBottom: "0px" }}
                to={`/home/${slugifier(name)}/${id}`}
                onClick={() => setExpanded(expanded ? false : expanded)}
              >
                <span
                  className="menu-item"
                  style={{
                    color: selectedCategory === id ? "#bb00bb" : "white",
                  }}
                >
                  {name}
                </span>
              </Link>
            );
          })}

        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{
            backgroundColor: "transparent",
          }}
        >
          {isLoggedIn ? (
            <AuthUserNav setExpanded={setExpanded} expanded={expanded} />
          ) : (
            <GuestUserNav setExpanded={setExpanded} expanded={expanded} />
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
