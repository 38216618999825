import React, { useState, useEffect, useRef } from "react";
import { Stack } from "react-bootstrap";
import { HooxButton } from "../commons/styled-bootstrap";
import { addBookmark } from "../../service/contentService";
import "./style.css";
/**
 * Component consuming the VdocipherAPI
 */

const VideoLayer = ({
  videoRef,
  isAPIReady,
  isVisible,
  setVisible,
  style,
  content,
  watchingContent,
  nextEpisodeParams: { nextEpisode, onNextEpisode },
}) => {
  const [status, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [sendWatch10, setSendWatch10] = useState(false);
  const [sendWatch30, setSendWatch30] = useState(false);
  const [sendWatch50, setSendWatch50] = useState(false);

  function openNextEpisode() {
    if (!nextEpisode || !player) {
      return;
    }
    player.video.pause();

    onNextEpisode();

    //console.log("watchingContent.usrRight:", watchingContent.usrRight);
    //console.log("watchingContent.name:", watchingContent.name);
  }

  function isNextButtonAvailable() {
    if (!nextEpisode || !watchingContent.videoEndTime) {
      return false;
    }

    const skipButtonTime = _convertEndTime(watchingContent.videoEndTime);

    return currentTime > skipButtonTime;
  }

  function _convertEndTime(time) {
    const split = time.split(":");
    if (split.length === 3) {
      const [hours, minutes, seconds] = split;
      return parseInt(hours) * 3600 + parseInt(minutes) * 60 + seconds;
    }
    const [hours, minutes] = time.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      // @todo detach from the API
      setPlayer(null);
      return;
    }
    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    // player.video.play();

    const listenerPlay = () => {
      //console.log("play:", player.video.currentTime);
      setStatus("Playing");
    };

    const listenerPause = () => setStatus("Paused");

    const listenerCanPlay = () => {
      setStatus("Ready");
      player.video.play();
    };

    const listenerTime = () => {
      setCurrentTime(player.video.currentTime);

      //const timeControl10 = player.video.currentTime > player.video.duration / 10;
    };

    const listenerEnd = () => {
      openNextEpisode();
    };

    player.video.addEventListener("ended", listenerEnd);
    player.video.addEventListener("playing", listenerPlay);
    player.video.addEventListener("pause", listenerPause);
    player.video.addEventListener("canplay", listenerCanPlay);
    player.video.addEventListener("timeupdate", listenerTime);

    window.player = player;
    return () => {
      player.video.removeEventListener("playing", listenerPause);
      player.video.removeEventListener("canplay", listenerCanPlay);
      player.video.removeEventListener("play", listenerPlay);
      player.video.removeEventListener("timeupdate", listenerTime);
      player.video.removeEventListener("ended", listenerEnd);
    };
  }, [isAPIReady, videoRef]);

  useEffect(() => {
    if (!player || !watchingContent) return;
    getContentBookmark();

    player.video.play();
    return () => {
      handleBookmark();
    };
  }, [player, watchingContent]);

  const onPress = async () => {
    if (player.video.paused) {
      player.video.play();
    } else {
      player.video.pause();
      await handleBookmark();
      setVisible(false);
    }
  };

  const onSkipIntro = () => {
    player.video.currentTime = content.introDuration;
  };

  const handleForwards = () => {
    player.video.currentTime = player.video.currentTime + 10;
  };

  const handleBackwards = () => {
    player.video.currentTime = player.video.currentTime - 10;
  };

  const getContentBookmark = async () => {
    if (!watchingContent || !watchingContent.bookmarkTime)
      player.video.currentTime = 0;
    player.video.currentTime = watchingContent.bookmarkTime;
  };

  const handleBookmark = async () => {
    await addBookmark(
      watchingContent.id,
      player.video.currentTime,
      watchingContent.duration
    );
  };

  const isTitleVisible = player && player.video && isVisible;
  const isTVSeries = content.seasons && content.seasons.length;
  const isSkipButtonAvailable = !!content.introDuration;
  const isSkipButtonShown =
    status === "Playing" && content.introDuration > currentTime;

  return (
    <>
      {isTitleVisible ? (
        <div style={style} onClick={onPress}>
          <h1 style={styles.title}>{content.title} </h1>
          {isTVSeries ? (
            <h5 style={styles.info}>
              {watchingContent.type === "EPISODE" ? watchingContent.title : " "}
            </h5>
          ) : null}
        </div>
      ) : null}
      {isSkipButtonAvailable ? (
        <div
          className="skip-button"
          onClick={onSkipIntro}
          style={
            isSkipButtonShown
              ? {
                  opacity: "100%",
                  visibility: "visible",
                }
              : {
                  visibility: "hidden",
                  opacity: 0,
                }
          }
        >
          {/* {TODO: Translate text} */}
          Skip
        </div>
      ) : null}
      {isNextButtonAvailable() ? (
        <HooxButton.Secondary
          variant="primary"
          style={styles.nextEpisode}
          onClick={openNextEpisode}
        >
          <Stack direction="horizontal" gap={2}>
            <i className="bi bi-play-fill" />
            <div className="me-auto text-center" style={{ width: "100%" }}>
              Sonraki Bölüm
            </div>
          </Stack>
        </HooxButton.Secondary>
      ) : null}
    </>
  );
};

export default VideoLayer;

const styles = {
  title: {
    color: "white",
    marginLeft: "74px",
    marginTop: "44px",
  },
  info: {
    color: "white",
    marginLeft: "74px",
    marginTop: "8px",
  },
  nextEpisode: {
    borderRadius: "15px",
    backgroundColor: "white",
    color: "black",
    width: "160px",
    position: "absolute",
    bottom: "100px",
    right: "5%",
    zIndex: 20,
  },
};
